import { Component, OnInit, OnDestroy } from '@angular/core';
import { AddQuestionComponent } from './components/add-question/add-question.component';
import { ApiQuestions } from './api-questions.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { QuestionsEditBtn } from './components/questions.edit.btn.component';
import { LocalDataSource } from '@vamidicreations/ng2-smart-table';
import { NbWindowService } from '@nebular/theme';
import { NbDialogService,NbDialogRef } from '@nebular/theme';
import { NbTreeGridDataSource, NbTreeGridDataSourceBuilder } from '@nebular/theme';
import { getParseTreeNode, isParseTreeNode } from 'typescript';
import { ModifyQuestionComponent} from './components/modify-question/modify-question.component';
import { DeleteQuestionComponent} from './components/delete-question/delete-question.component';
import { ApiService } from '../services/api-services.service';

interface TreeNode<T> {
  data: T;
  details?: TreeNode<T>[];
  expanded?: boolean;
}

interface FSEntry {
  _id: string;
  text: string;
  type:string;
}

@Component({
    selector: 'app-questions',
    templateUrl: './questions.component.html',
    styleUrls: ['./questions.component.scss']
  })

export class QuestionsComponent implements OnInit,OnDestroy{
  nbItems; loading; page; queryParams;
  editing: boolean;
  questions: TreeNode<FSEntry>[] = [];
  hasMore = true;
  
  expanded: boolean;
  private subscriptions$: Subscription = new Subscription();
  customColumn = 'Id';
  optionColumn = 'Options'
  defaultColumns = [ 'text' ];
  allColumns = [ this.customColumn, ...this.defaultColumns,this.optionColumn ];
 
  dataSource: NbTreeGridDataSource<FSEntry>;
  services: object[];

  constructor(dataSourceBuilder: NbTreeGridDataSourceBuilder<FSEntry>,
    private route: ActivatedRoute,
    private apiQuestions: ApiQuestions,
    private apiService: ApiService,
   private windowQuestion: NbWindowService,
   private windowQuestion2: NbWindowService,
  private dialogQuestion: NbDialogService
   ) {
    this.dataSource = dataSourceBuilder.create(this.questions);
  }
  private dialogRefQuestion:NbDialogRef<any>;


  ngOnInit() {
         
        const sub = this.apiQuestions.getQuestions(this.queryParams).subscribe(res => {
          this.questions = [];
          this.questions = this.questions.concat(...res.data);
          this.apiQuestions.questionsEmit(this.questions);
          sub.unsubscribe();
        }, err => {
          console.log(err);
          sub.unsubscribe();
        });
        this.subscriptions$.add(this.apiQuestions.questions.subscribe(data => {
          if (data) {
            console.log("data--------------:",data)
            

          let newList=data.map((el)=>{
            
            return(
              
              el.details&&el.details.length>0 ? {
            children:el.details.map((item)=>({data:{...item,services:item&&item.services,type:"child",parentElement:el}})),
            data:{_id:el._id,text:el.text,services:el.services,type:"parent",details:el.details}
          }: {
            children:[],
            data:{_id:el._id,text:el.text,services:el.services,type:"parent",details:el.details}
          }   
          
          )
                 
        });

         
          console.log("newList:",newList)
          this.questions = newList;
          
          this.dataSource.setData(this.questions)
           localStorage.setItem("dataQLength",data.length)
            //console.log("source:",this.source)
            this.loading = false;
          }
        }));
      
        /// get list services
        const sub2 = this.apiService.getAllServices().subscribe(res => {
          this.services = [];
          this.services = this.services.concat(...res.data);
          console.log("res:"+res.data)
          this.apiService.servicesEmit(this.services);
          sub.unsubscribe();
        }, err => {
          console.log(err);
          sub.unsubscribe();
        });
        this.subscriptions$.add(this.apiService.services.subscribe(data => {
          if (data) {
            console.log("data:",data)
            
           this.services=data;
         ///--------------------------------------//
         localStorage.setItem("listServices",JSON.stringify(data));
         //--------------------------------------//
          }
        }));
      
        
      }
    
            
  openAddQuestionWindow() {
    this.editing = true;
    this.subscriptions$.add(
      this.windowQuestion.open(AddQuestionComponent, { title: 'Ajouter une question' }).onClose.subscribe(() => {
        this.editing = false;
      })
    );
  }
  private data: FSEntry[] = [];
  
  openEditWindow(row) {
    this.editing = true;
    this.subscriptions$.add(
      this.windowQuestion2.open(ModifyQuestionComponent, {
        title: 'Modifier cette question',
        context: { question: row }
      }).onClose.subscribe(() => {
        this.editing = false;
      })
    );
  }

  open(closeOnBackdropClick: boolean,row:any) {
    console.log("row:",row)
   this.dialogRefQuestion= this.dialogQuestion.open(DeleteQuestionComponent, {context: {question:row },closeOnBackdropClick:closeOnBackdropClick});
 }

 

 openModify(closeOnBackdropClick: boolean,row:any) {
 console.log("row:",row)
 this.dialogRefQuestion= this.dialogQuestion.open(ModifyQuestionComponent, {context: {question:row },closeOnBackdropClick:closeOnBackdropClick});
}

openAdd(closeOnBackdropClick: boolean,row:any) {
  console.log("row:",row)
 this.dialogRefQuestion= this.dialogQuestion.open(AddQuestionComponent, {context: {question:row },closeOnBackdropClick:closeOnBackdropClick});
}

getRowData(row){
console.log("row:",row)
  }

  isChild(question){
    if(question.type==="parent")
    return false
    else return true
  }
    ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }
}